import styled, { css } from "styled-components";
import {
  BP,
  Colors,
  Rem,
} from "../../commons/Theme";

export const StyledImageList = styled.div`
  color: ${Colors.white};
`;

export const StyledImageListItems = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  &:after {
    content: '';
    display: block;
    min-width: ${Rem(50)};
    max-width: ${Rem(50)};
    height: 1px;

    @media (${BP.ipad}) {
      display: none;
    }
  }
`;

export const StyledImageListItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-left: ${Rem(50)};
  min-width: ${Rem(120)};

  @media (${BP.ipad}) {
    margin: initial;
    width: 100%;
  }
`;

export const StyledImageListImage = styled.div`
  height: ${Rem(80)};
  width: ${Rem(80)};
  margin-bottom: ${Rem(40)};
  background-image: url('${(props) => props.src}');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export const StyledImageListLabel = styled.div`
  text-align: center;
  font-size: ${Rem(15)};
  line-height: ${Rem(25)};
`;
